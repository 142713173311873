<template lang="pug">
#spo2
    b-container.content-wrap.light-font.pt-2
        .entity-head
            b-row.entity-head-content.mb-4
                b-col(cols="12") 
                    h1.mt-blue.light-font.mb-2 O2
                b-col(cols="12" md="4")
                    b-img.link-img.w-100(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/N2_Orantech_O2_compatible_medical_cables_and_sensors.jpg" alt="")
                b-col.mt-2(cols="12" md="8")
                    p Orantech’s oxygen sensors are manufactured by our partner in Germany and designed to replace original sensors used with the following brands of anesthesia machines and ventilators: Drager, GE/Datex-Ohmeda, Hamilton and Hudson. Our oxygen products are compatible alternatives to the following brand of O2 sensors: EnviteC, City Technology, Analytical Industries and Maxtec.
        .item-wrap(v-for="(sp, i) in oTwoData" :key="i")
            AboutProductItem(
            :title="sp.title"
            :dateInfo="sp.dateInfo"
            :brands="sp.brands"
            :models="sp.models"
            :features="sp.features"
            :imageUrls="sp.imageUrls"
            :popularProducts="sp.popularProducts"
            :downloadUrls="sp.downloadUrls")
    MissionPromiseStatement
    Footer
</template>

<script>
  import AboutProductItem from '@/components/AboutProductItem.vue'
  import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
  import Footer from '@/components/Footer.vue';
  export default {
        components: {
            AboutProductItem,
            MissionPromiseStatement,
            Footer
        },
        data: () => ({
            oTwoData: [
                {
                    title: "Oxygen Sensors",
                    brands: ["Draeger", "Datex-Ohmeda", "Siemens", "Hamilton", "EnviteC", "Hudson-RCI", "City Technologies", "Maxtec", "Honywell/EnviteC", "Analytical Industries"],
                    features: [
                        "Made in Germany",
                        "Precise measurement and quick response",
                        "Long life service",
                        "Packed in aluminum can",
                        "CE certificated and FDA 510K clearance"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/1-11.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/2.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Oxygen-Sensors-scaled.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/3-9.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/4-8.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/5-1.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/6.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/7.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/8.jpg"
                    ],
                    downloadUrls: [
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/04/Oxygen_sensors.pdf",
                            message: "Oxygen Sensors Brochure"
                        }
                    ]
                },
            ]
        })
        
    }
</script>

<style lang="scss" scoped>
.link-img {
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
}
</style>