<template lang="pug">
#spo2
    b-container.content-wrap.light-font.pt-2
        .entity-head
            b-row.entity-head-content.mb-4
                b-col(cols="12") 
                    h1.mt-blue.light-font.mb-2 EtCO2
                b-col(cols="12" md="4")
                    b-img.link-img.w-100(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_EtCO2_compatible_medical_cables_and_sensors.jpg" alt="")
                b-col.mt-2(cols="12" md="8")
                    p Our replacement EtCO2 sensors and supplies for the after sales market are compatible with the following brands of patient monitoring systems: Respironics, Philips, Phasein, Mindray and Zoll.
        .item-wrap(v-for="(sp, i) in etcoData" :key="i")
            AboutProductItem(
            :title="sp.title"
            :dateInfo="sp.dateInfo"
            :brands="sp.brands"
            :models="sp.models"
            :features="sp.features"
            :imageUrls="sp.imageUrls"
            :popularProducts="sp.popularProducts"
            :downloadUrls="sp.downloadUrls")
    MissionPromiseStatement
    Footer
</template>

<script>
  import AboutProductItem from '@/components/AboutProductItem.vue'
  import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
  import Footer from '@/components/Footer.vue';
  export default {
        components: {
            AboutProductItem,
            MissionPromiseStatement,
            Footer
        },
        data: () => ({
            etcoData: [
                {
                    title: "Transducers",
                    brands: ["Respironics", "Philips", "Phasein", "Zoll", "Mindray/Datascope", "Hamilton", "Nihon Kohden"],
                    features: [
                        "Functionality and performance equivalent to OEMs",
                        "Options of mainstream and side stream",
                        "After market replacements saving up to 50%",
                        "CE certificate",
                        "Exclusive FDA 510K as the replacements",
                        "12 months warranty"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EtCo2-Orantech-compatible-transducer-family.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EtCo2-Orantech-Philips-compatible-main-stream-transducer.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/ETCO2-CTM_PH01.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EtCo2-Orantech-Respironics-compatible-side-stream-transducer.jpg"
                    ],
                    downloadUrls: [
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/03/User_Manual_EtCO2_Sensors.pdf",
                            message: "EtCO2 Sensors User Manual"
                        },
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/04/DOC-007-01-Declaration-of-Conformity-ETCO2-Sensor.pdf",
                            message: "Declaration of Conformity ETCO2 Sensor"
                        },
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/04/EtCO2_Sensors.pdf",
                            message: "EtCO2 Sensors Brochure"
                        }
                    ],
                    popularProducts: [
                        {
                            number: "CTM-RP01",
                            description: "Respironics Main stream EtCo2 sensor, 2.5m",
                            brands: "Respironics",
                            references: "CAPNOSTAT 51015928",
                            package: "1 set/box"
                        },
                        {
                            number: "CTS-RP01",
                            description: "Respironics Side Stream EtCo2 sensor, 0.75m",
                            brands: "Respironics",
                            references: "Loflo,1022054",
                            package: "1 set/box"
                        },
                        {
                            number: "CTM-PH01",
                            description: "Philips Main stream EtCo2 sensor, 2.5m",
                            brands: "Philips",
                            references: "M2501A",
                            package: "1 set/box"
                        },
                        {
                            number: "CTM-ZL01",
                            description: "Zoll Main stream EtCo2 sensor, 2.5m",
                            brands: "Zoll",
                            references: "8000-0312",
                            package: "1 set/box"
                        },
                        {
                            number: "CTM-MR01",
                            description: "Mindray Main stream EtCo2 sensor, 2.5m",
                            brands: "Mindray",
                            references: "0010-21-42760",
                            package: "1 set/box"
                        },
                        {
                            number: "CTM-HT01",
                            description: "Hamilton Main stream EtCo2 sensor, 2.5m",
                            brands: "Hamilton",
                            references: "281718",
                            package: "1 set/box"
                        }
                    ],
                },
                {
                    title: "EtCo2 Supplies",
                    brands: ["All Brands", ],
                    features: [
                        "Quality supplies for main-stream and side-stream transducers",
                        "Airway adaptors",
                        "Water filters",
                        "Sampling cannula"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EtCo2-Orantech-EtCo2-products.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/CTA_M04.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/CTA_M03.jpg"
                    ],
                },
            ]
        })
        
    }
</script>

<style lang="scss" scoped>
.link-img {
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
}
</style>