<template lang="pug">
#spo2
    b-container.content-wrap.light-font.pt-2
        .entity-head
            b-row.entity-head-content.mb-4
                b-col(cols="12") 
                    h1.mt-blue.light-font.mb-2 Anesthesia &amp; Respiratory
                b-col(cols="12" md="4")
                    b-img.link-img.w-100(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/AR-Accessories-1.png" alt="")
                b-col.mt-2(cols="12" md="8")
                    p Orantech continues to make the great efforts on providing the excellent services in the after sales market. Anesthesia & Respiratory accessories will be the next most needed to our global customers. Starting with the replacements of Humidification System accessories and supplies, Orantech will be on the journey of exploring more quality accessories and supplies but affordable beyond the OEMs for the Anesthesia and Respiratory equipment.
        .item-wrap(v-for="(sp, i) in anesthesiaData" :key="i")
            AboutProductItem(
            :title="sp.title"
            :dateInfo="sp.dateInfo"
            :brands="sp.brands"
            :models="sp.models"
            :features="sp.features"
            :imageUrls="sp.imageUrls"
            :popularProducts="sp.popularProducts"
            :downloadUrls="sp.downloadUrls")
    MissionPromiseStatement
    Footer
</template>

<script>
  import AboutProductItem from '@/components/AboutProductItem.vue'
  import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
  import Footer from '@/components/Footer.vue';
  export default {
        components: {
            AboutProductItem,
            MissionPromiseStatement,
            Footer
        },
        data: () => ({
            anesthesiaData: [
                {
                    title: "Humidifier Accessories",
                    brands: ["Fisher & Paykel"],
                    features: [
                        "Engineered to functionally equivalent to OEMs",
                        "Compatible with Fisher & Paykel Humidifier",
                        "Temperature and Flow probes",
                        "Heater-wire adapters",
                        "Humidification Chambers",
                        "12 months warranty"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/AR-HC-TNC_AR_FP_001-1.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/AR-TSF_FP02_15.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/AR-TAF_FP01_09.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/AR-TAF_FP02_045.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/AR-TAF_FP03_045.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/AR-HC-TNC_AR_FP_001-1.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/AR-HC-TNC_AR_FP_002-1.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/AR-HC-TNC_AR_FP_BS-1.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/AR-HC-TNC_AR_FP_002-TNC_AR_FP_BS合体图-1.jpg"
                    ],
                    popularProducts: [
                        {
                            number: "TSF-FP01-15",
                            description: "Temperature and flow probe, 1.5m",
                            brands: "",
                            references: "900MR869",
                            package: "1 pcs/bag"
                        },
                        {
                            number: "TAF-FP01-09",
                            description: "Dual Heater wires Adaptor, 0.9m",
                            brands: "",
                            references: "900MR805",
                            package: "1 pcs/bag"
                        }
                    ],
                },
            ]
        })
        
    }
</script>

<style lang="scss" scoped>
.link-img {
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
}
</style>