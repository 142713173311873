<template lang="pug">
#accessory
  b-container.content-wrap.light-font
    b-row
      b-col.link-img-wrap(cols="6" md="3" lg="2" v-for="(accessory, key) in accessories" :key="key")   
        b-link
        //- <router-link :to="{ path: accessory.url }">{{  }}</router-link>
        b-link(href="#" :to="{path: accessory.url}") 
        
          b-img.link-img.mt-blue(:src="accessory.image_url")
        b-link.link-img-title.d-block(href="#" :to="{path: accessory.url}") 
          TranslateValue(:sourceObj="accessory")  
    br      
    h1.accessories-title.light-font.mt-blue {{ $t('accessories.acc_heading') }} 
    br
    p {{ $t('accessories.access_1') }}
        //- a(href="/downloads") {{ $t('accessories.downloads') }} 
        //- | {{ $t('accessories.access_2') }}
    
    //- h2.parts-title.light-font.mt-blue.vertical-space-above Review - Patient Monitor Accessory Cables
    //- p See why biomed companies choose our patient monitor accessory cables. Here a biomed technician on their YouTube channel from
    //-     a(href="https://www.youtube.com/channel/UCwqIAuVwKhUwolQFmbLAf5g" target="_blank") FOBI Medical
    //-     | reviews our compatible cables: ECG Leadwires, SpO2 Sensors, and SpO2 Adapter Cables.
    //- blockquote
    //-   .quote
    //-     | "There are a lot of things you guys should know about cables. 
    //-     | Strain relief is extremely important... 
    //-     | This right here is a major point of failure... 
    //-     | The other thing that we have to consider when we talk about all these cables is [that] they have to be cleanable and they have to be designed with the intent for longevity... 
    //-     | You can see we have the cables with... a little orange dot on them... the cables that we have personally picked out to sell here at FOBI Medical.
    //-     br
    //-     | [About ECG Leadwires:]
    //-     | The first thing that grabbed me... is a pinch style connector... [with] a pinch guard so you can't pinch the patient... that's good. 
    //-     | The tolerances on the clamps are pretty tight...
    //-     | The material is... silicone cabling... that does help with [it] being a tangle-free cable set...
    //-     | The point of failure on most cables is going to be where the soft structure meets the rigid structure and FOBI cables have a long dingle hopper of a strain relief... so that actually eases the burden on this point right here and I don't honestly think it's going to fail there...
    //-     | Even if I pull one cable it flexes the whole entire structure... 
    //-     b.bolder This is going to be a long life cable set, I guarantee it.
    //-     br
    //-     | [About SpO2 Sensors:]
    //-     | Now there's not too much difference in SpO2, right? You would think so, but this SpO2 cable...
    //-     | it's got a long solid strain relief... where the cable meets the rigid structure...
    //-     | [There is also a] long flexible strain relief on the connector. This connector feels like it's solid molded... and this is going to be a design improvement over the oem...
    //-     | Take a look at the light guards... on this guy [they] are solid plastic...
    //-     | On the oem... you have these white rubber flaps on the side and they do very little functionally...
    //-     | But this guy... most of the clamp is solid so if you do get something on it all you got to do is wash it down and press on.
    //-     | You can see the LEDs inside it very nice... soft pillows... softer than the oem...
    //-     b.bolder It's a good SpO2 probe.
    //-     br
    //-     | [About SpO2 Adapter Cables:]
    //-     | Now this is the cable that is going to get damaged the most because this one is around the patient bed... around wheelchairs... around a lot of different things. 
    //-     | This has got the soft flexible strain reliefs... It's got good clamps, really good spring pressure on those retention hooks... and it's got the lockdown shield which when it's closed it's on there pretty good.
    //-     | Long smooth strain relief silicone jacketed... A large diameter trunk cable... and it's got a noise suppression choke on the cable...
    //-     | There is definitely a difference between the cables that are sold by competitors and the cables that we have personally picked out to sell here at FOBI Medical...
    //-     | These ones that have an orange indicator... [the manufacturer] put a lot of design thought process into their product...
    //-     b.bolder I would have to agree that these are... some of the best... I have ever seen.
    //-   .reference
    //-     b.bolder Justin at FOBI Medical
    //- .video
    //-     iframe(src="https://www.youtube.com/embed/PjzH4V4Z6jc?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen)
    //- br
  
  MissionPromiseStatement 
  Footer 
</template>

<script>

import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
import Footer from '@/components/Footer.vue';
import TranslateValue from '../../components/TranslateValue.vue';

export default {
  name: 'Accessories',
  components: {
    Footer,
    MissionPromiseStatement,
    TranslateValue
  },

  data(){
      return {
          accessories:[
              {
                  id: 1,
                  name_en: 'SpO2',
                  name_zh:'血氧',
                  image_url: 'https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_SpO2_compatible_medical_cables_and_sensors.jpg',
                  url:'/accessories/spo2'
              },
              {
                  id: 2,
                  name_en: 'ECG',
                  name_zh:'监护心电',
                  image_url: 'https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_ECG_compatible_medical_cables_and_sensors.jpg',
                  url:'/accessories/ecg'
              },
              {
                  id: 3,
                  name_en: 'EKG',
                  name_zh:' 诊断心电',
                  image_url: 'https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_EKG_compatible_medical_cables_and_sensors.jpg',
                  url:'/accessories/ekg'
              },
              {
                  id: 4,
                  name_en: 'NIBP',
                  name_zh:'无创血压',
                  image_url: 'https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_NIBP_compatible_medical_cables_and_sensors.jpg',
                  url:'/accessories/nibp'
              },
              {
                  id: 5,
                  name_en: 'TEMP Cardiac Output',
                  name_zh:'体温及心排量',
                  image_url: 'https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_Temp_compatible_medical_cables_and_sensors.jpg',
                  url:'/accessories/temp'
              },
              {
                  id: 6,
                  name_en: 'IBP',
                  name_zh:'有创压',
                  image_url: 'https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_IBP_compatible_medical_cables_and_sensors.jpg',
                  url:'/accessories/ibp'
              },
              {
                  id: 7,
                  name_en: 'Fetal',
                  name_zh:' 胎监',
                  image_url: 'https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_Fetal_compatible_medical_cables_and_sensors.jpg',
                  url:'/accessories/fetal'
              },
              {
                  id: 8,
                  name_en: 'EtCO2',
                  name_zh:'二氧化碳',
                  image_url: 'https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_EtCO2_compatible_medical_cables_and_sensors.jpg',
                  url:'/accessories/etco2'
              },
              {
                  id: 9,
                  name_en: 'Multi-Parameter',
                  name_zh:'多参数',
                  image_url: 'https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_Multiparameter_compatible_medical_cables_and_sensors.jpg',
                  url:'/accessories/multiparameter'
              },
              {
                  id: 10,
                  name_en: 'EEG',
                  name_zh:'脑电',
                  image_url: 'https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_EEG_compatible_medical_cables_and_sensors.jpg',
                  url:'/accessories/eeg'
              },
              {
                  id: 11,
                  name_en: 'O2',
                  name_zh:'氧电池',
                  image_url: 'https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/N2_Orantech_O2_compatible_medical_cables_and_sensors.jpg',
                  url:'/accessories/o2'
              },
              {
                  id: 12,
                  name_en: 'Orthopedics',
                  name_zh:'骨科/压缩设备附件',
                  image_url: 'https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orth-Tourniquet-Accessories.jpg',
                  url:'/accessories/orthopedics'
              },
              {
                  id: 13,
                  name_en: 'Batteries',
                  name_zh:'电池',
                  image_url: "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Batteries-1.jpg",
                  url:'/accessories/batteries'
              },
              {
                  id: 14,
                  name_en: 'Anesthesia Respiratory',
                  name_zh:'麻醉呼吸',
                  image_url: "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/AR-Accessories-1.png",
                  url:'/accessories/anesthesia'
              },
              {
                  id: 15,
                  name_en: 'Vet Monitoring',
                  name_zh:'动物监护附件',
                  image_url: "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Vet-Accessories-scaled.jpg",
                  url:'/accessories/vetmonitoring'
              }
          ]
      };
  },
}
</script>

<style lang="scss" scoped>
  .link-img-wrap {
    text-align: center;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
  }
  .link-img {
    margin: 20px 0 0;
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
  }
  .link-img-block {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .link-img-title {
    text-decoration: none;
    color: #68C3C5;
    font-weight: 600;
    background-color: transparent;
  }
  .video {
    text-align: center;
    iframe{
        width: 100%;
        height: 580px;
        @media(max-width: 767px){
            width: 100%;
            height: 300px;
        }
        @media(max-width: 543px){
            width: 100%;
            min-height: 80vw;
        }
    }
  }
  .quote {
    padding-right: 50px;
    padding-left: 50px;
    font-style: oblique;
  }
  .reference {
    text-align: right;
    padding-right: 95px;
    padding-bottom: 10px;
  }
  .bolder {
    font-weight:700;
  }
  .vertical-space-above {
    padding-top: 20px;
  }
</style>