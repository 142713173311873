<template lang="pug">
#about
  AddressPage
  b-container.top-wrap-about
    .text-center.mt-4
      h4.page-heading.text-primary {{ $t('address.source') }}

    p {{ $t('about.medten_inc_p1') }}

    p {{ $t('about.medten_inc_p2') }}

    p {{ $t('about.medten_inc_p3') }}

    //- p Our team is composed of experienced medical device professionals, specialized consultants, and talented business professionals from around the globe.

    //- p Our vision is to be the largest source for medical repair parts and accessories. We value excellent people as our most important resource, respect our environment, and give back to our communities.

    //- p We currently have offices in Irvine, California (servicing the US market), Denmark (servicing the European market) and Shenzhen (servicing the Chinese market).
 
  MissionPromiseStatement
  Footer
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'pinia';
import { useBaseStore } from '@/stores/base-st';
import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
import Footer from '@/components/Footer.vue';
import AddressPage from '../components/AddressPage.vue'
export default {
  name: 'About',
  components: {
    MissionPromiseStatement,
    Footer,
    AddressPage
  },
  data(){
    return {
    };
  },

  computed: {
    ...mapState(useBaseStore, ['message']),
  },
 
  created(){

  },

  mounted(){
  },
  
  watch:{

  },

  methods:{
    
  },
  
}
</script>