<template lang="pug">
#spo2
    b-container.content-wrap.light-font.pt-2
        .entity-head
            b-row.entity-head-content.mb-4
                b-col(cols="12") 
                    h1.mt-blue.light-font.mb-2 SpO2
                b-col(cols="12" md="4")
                    b-img.link-img.w-100(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_SpO2_compatible_medical_cables_and_sensors.jpg" alt="")
                b-col.mt-2(cols="12" md="8")
                    p The quality of the product and accuracy of readings on SpO2 replacement products are top priority. Our entire range of SpO2 sensors and cables are compatible with the following brands and the technologies built into the patient monitoring systems: BCI, Criticare, GE, Mindray/Datascope, NIhon Kohden, Masimo, Nellcor, Nonin, Philips, Spacelabs and more.
        .item-wrap(v-for="(sp, i) in spData" :key="i")
            AboutProductItem(
            :title="sp.title"
            :dateInfo="sp.dateInfo"
            :brands="sp.brands"
            :models="sp.models"
            :features="sp.features"
            :imageUrls="sp.imageUrls"
            :popularProducts="sp.popularProducts"
            :downloadUrls="sp.downloadUrls")
    MissionPromiseStatement
    Footer
</template>

<script>
  import AboutProductItem from '@/components/AboutProductItem.vue'
  import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
  import Footer from '@/components/Footer.vue';
  export default {
        components: {
            AboutProductItem,
            MissionPromiseStatement,
            Footer
        },
        data: () => ({
            spData:[
                {
                    title: "Reusable Sensors",
                    brands: ["Coviden/Nellcor", "Masimo", "Philips", "GE Healthcare", "Nihon Kohden", "Spacelabs", "Mindray/Datascope"],
                    features: [
                        "Replacements for OEM, fully compatible",
                        "Patient sizes from Neonateto Large Adult",
                        "Options: Finger Clip, Soft Tip, Multi-Y Style, Neonate Wrap, Ear Clip and Forehead",
                        "Cost savings up to 50%",
                        "CE certificate and FDA 510(K) clearance",
                        "12 months warranty"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/1-3-7.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-Orantech-Earclip-sensors.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-Orantech-fingerclip-sensors-family.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-Orantech-Multi-site-sensors.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-Orantech-soft-sensors-with-excellent-performance.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SS_001XC_AF10.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SS_006RB_MY10.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SS_009RB_AF30.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SS_016_AS30.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SS_024_AE20.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SS_038_NW30.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SS_043_AS16.jpg",
                    ],
                    downloadUrls: [
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/04/M20_M006_Reusable_SpO2_Sensors_QR-1.pdf",
                            message: "Reusable SpO2 Sensors User Manual"
                        },
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/04/DOC-002-01-Declaration-of-Conformity-SpO2-Sensor.pdf",
                            message: "Declaration of Conformity SpO2 Sensors"
                        }
                    ],
                    popularProducts: [
                        {
                            number: "SS-001XN-AF10",
                            description: "Nellcor-Oximax adult finger clip sensor, 1.0m",
                            brands: "Nellcor Oximax	",
                            references: "DS100A",
                            package: "1 pc/bag"
                        },
                        {
                            number: "SS-001XN-MY10",
                            description: "Nellcor-Oximax Multi-site Y sensor, 1.0m",
                            brands: "Nellcor Oximax	",
                            references: "D-YS",
                            package: "1 pc/bag"
                        },
                        {
                            number: "SS-005RB-AF10",
                            description: "Masimo LNCS adult finger clip sensor, 1.0m",
                            brands: "Masimo",
                            references: "1863",
                            package: "1 pc/bag"
                        },
                        {
                            number: "SS-005RB-AE10",
                            description: "Masimo LNCS adult ear clip sensor, 1.0m",
                            brands: "Masimo",
                            references: "1895",
                            package: "1 pc/bag"
                        },
                        {
                            number: "SS-016-AS30",
                            description: "GE Trusignal Soft tip SpO2 Sensor,3.0m E",
                            brands: "GE Healthcare",
                            references: "TS-SA4-G",
                            package: "1 pc/bag"
                        }
                    ],
                },
                {
                    title: "Single Use Sensors",
                    brands: ["Coviden/Nellcor", "Masimo", "Philips", "GE Healthcare", "Nihon Kohden", "BCI", "Nonin", "Datex"],
                    features: [
                        "Functionally performances as OEM’s",
                        "Single-Patient use",
                        "Patient sizes from Neonate to Adult",
                        "Options: Adhesive textile and Non-adhesive foam",
                        "Cost savings up to 50%",
                        "CE certificate and FDA 510(K) clearance"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-Oranech-single-patient-use-sensors.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-Orantech-single-patient-use-family-2.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-Orantech-single-patient-use-sensors-Non-adhesive.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-Orantech-single-patient-use-sensors-Textile.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SSD_001XC_W09A.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SSD_001XC_W09AN.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SSD_001_S09AN.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SSD_005RB_W09A.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SSD_006RB_S09AN.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SSD_018_S09AN.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SSD_019_W09A.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SSD_043_W09A.jpg"
                    ],
                    downloadUrls: [
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/02/Disposable_Spo2_Sensors.pdf",
                            message: "Disposable SpO2 Sensors Brochure"
                        },
                        {
                            url: "https://orantech.com/wp-content/uploads/2020/10/M20_M008_Single_patient_use_SpO2_Sensors_QR.pdf",
                            message: "Disposable SpO2 Sensors User Manual"
                        }
                    ],
                    popularProducts: [
                        {
                            number: "SSD-001XC-W09A",
                            description: "Oximax/Adult>40Kg, Adhesive Non woven textile, 0.9m",
                            brands: "Nellcor Oximax",
                            references: "MAXA, MAXAL",
                            package: "24 pcs/box"
                        },
                        {
                            number: "SSD-001XC-W09AN",
                            description: "Oximax/Neonate/Adult<3kg or >40Kg, Adhesive Non woven textile, 0.9m",
                            brands: "Nellcor Oximax",
                            references: "MAXAN",
                            package: "24 pcs/box"
                        },
                        {
                            number: "SSD-005RB-W09A",
                            description: "Masimo LNCS/Adult>40Kg, Adhesive Non woven textile, 0.9m",
                            brands: "Masimo",
                            references: "1859, Adtx",
                            package: "20 pcs/box"
                        },
                        {
                            number: "SSD-005RB-W09AN",
                            description: "Masimo LNCS/Neonate/Adult<3kg or >40Kg, Adhesive Non woven textile, 0.9m",
                            brands: "Masimo",
                            references: "1862, Neo",
                            package: "20 pcs/box"
                        }
                    ],
                },
                {
                    title: "Adapter Cables",
                    brands: ["Coviden/Nellcor", "Masimo", "Philips", "GE Healthcare", "Nihon Kohden", "Spacelabs", "Mindray/Datascope", "Draeger/Siemens"],
                    features: [
                        "Functionally performances as OEM’s",
                        "Compatible with major technology Spo2 sensors",
                        "Durable and reliable connection",
                        "Spo2 Technology convert adaptor with Oximax and Masimo",
                        "CE certificate and FDA 510(K) clearance",
                        "12 months warranty"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-adaptor-SAD_001X_XC-scaled.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-Oranech-adaptor-cables.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-Orantech-Sensor-and-cables-family.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-Orantech-Spacelabs-adaptor-cables.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SA_003X_30.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SA_015X_30.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SA_017_24TS.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SA_028X_12.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-SA_053RB_24LS.jpg"
                    ],
                    downloadUrls: [
                        {
                            url: "https://orantech.com/wp-content/uploads/2022/03/DOC-001-04Declaration-of-Conformity-SPO2-Adapter-Cables.pdf",
                            message: "Declaration of Conformity SPO2 Adapter Cables"
                        }
                    ],
                    popularProducts: [
                        {
                            number: "SA-015X-30",
                            description: "GE Oximax SpO2 Adaptor Cable,3.0m",
                            brands: "GE Healthcare",
                            references: "2021406-001	",
                            package: "1 pc/bag"
                        },
                        {
                            number: "SA-045X-30",
                            description: "Nihon Kohden Oximax SpO2 Adaptor Cable,3.0m",
                            brands: "Nihon Kohden",
                            references: "JL-650P",
                            package: "1 pc/bag"
                        },
                        {
                            number: "SA-003X-30",
                            description: "Nellcor-Oximax adapter cable, 3.0m",
                            brands: "Nellcor Oximax",
                            references: "DOC-10",
                            package: "1 pc/bag"
                        },
                        {
                            number: "SAD-001X-XC",
                            description: "Nellcor-Oximax adaptor, used with Non-Oximax sensors",
                            brands: "Nellcor Oximax",
                            references: "",
                            package: "1 pc/bag"
                        }
                    ],
                },
                {
                    title: "Y Sensors Accessories",
                    brands: ["Coviden/Nellcor", "Masimo", "Orantech"],
                    features: [
                        "To be ear clip sensors",
                        "To be used as Vet sensors",
                        "Fix the sensors onto the designed position",
                        "Reusable and disposable tapes available"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-M04_G005.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-M04_G006.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-M04_G007.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-M04_N002.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-M04_W014.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2-Y-sensors-M04_S013-1.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Spo2_accessories.jpg",
                    ],
                },
                {
                    title: "Disposable Position Tapes",
                    brands: ["Coviden/Nellcor", "Masimo", "Orantech"],
                    features: [
                        "Replace the standard traditional disposable SpO2 sensor",
                        "Reduce your operating costs significantly",
                        "Compatible with orginal Coviden/nellcor and Masimo Y sensors",
                        "Compatible with major brands being used with Orantech Y sensors",
                        "Durability and Flexibility",
                        "Comfort and Ease-of-Use",
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/N_Disposable_Position_Tapes_no_border.jpg"
                    ],
                    downloadUrls: [
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/03/Disposable_Position_Tape-EN.pdf",
                            message: "Disposable Positioning Tape Brochure"
                        }
                    ]
                }
            ]
        })
        
    }
</script>

<style lang="scss" scoped>
.link-img {
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
}
</style>