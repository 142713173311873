<template lang="pug">
#ekg
    b-container.content-wrap.light-font.pt-2
        .entity-head
            b-row.entity-head-content.mb-4
                b-col(cols="12") 
                    h1.mt-blue.light-font.mb-2 EKG
                b-col(cols="12" md="4")
                    b-img.link-img.w-100(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_EKG_compatible_medical_cables_and_sensors.jpg" alt="")
                b-col.mt-2(cols="12" md="8")
                    p Our entire range of EKG cables and leadwires are compatible with the following brands: Burdick, Fukuda Denshi, GE Marquette, Kenz, Mortara, Nihon Kohden, Philips, Quinton, Schiller, Welch Allyn and more.
        .item-wrap(v-for="(sp, i) in ekgData" :key="i")
            AboutProductItem(
            :title="sp.title"
            :dateInfo="sp.dateInfo"
            :brands="sp.brands"
            :models="sp.models"
            :features="sp.features"
            :imageUrls="sp.imageUrls"
            :popularProducts="sp.popularProducts"
            :downloadUrls="sp.downloadUrls")
    MissionPromiseStatement
    Footer
</template>

<script>
  import AboutProductItem from '@/components/AboutProductItem.vue'
  import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
  import Footer from '@/components/Footer.vue';
  export default {
        components: {
            AboutProductItem,
            MissionPromiseStatement,
            Footer
        },
        data: () => ({
            ekgData: [
            {
                title: "Cables and Lead wires",
                brands: ["Philips", "GE Healthcare", "Nihon Kohden", "Siemens/Burdick", "Schiller"],
                features: [
                    "Performance equivalent to OEMs",
                    "One-piece Cables, Trunk Cables, Lead wires",
                    "Electrodes options: 4mm Banana, 3mm Needle, GE universal, Snap and Grabber",
                    "AHA/IEC code available",
                    "CE certificate and FDA 510(K) clearance",
                    "6 months warranty"
                ],
                imageUrls: [
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Orantech-Accessories.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Orantech-compatible-lead-wires.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Orantech-GE-compatible-trunk-cables.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Orantech-One-piece-cable.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Orantech-One-piece-cables.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Orantech-Philips-compatible-lead-wires.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Orantech-Spacelabs-compatible-trunk-cable.jpg"
                ],
                popularProducts: [
                    {
                        number: "EKC10R-PH-B-A",
                        description: "Philips/One Piece ECG Cables, 10 lead, With Resistance2.2m+0.8m/1.2m",
                        brands: "Philips",
                        references: "M3702C",
                        package: "1 pc/bag"
                    },
                    {
                        number: "EKL-PWB10-B-A",
                        description: "Philips/ECG Lead wires, 10 lead, 0.9m",
                        brands: "Philips",
                        references: "9.89803E+11",
                        package: "1 set/bag"
                    },
                    {
                        number: "EKL-QTA10-G-A",
                        description: "Quinton/ECG Lead wires, 10 lead, 0.9m",
                        brands: "Quinton",
                        references: "031257-002",
                        package: "1 set/bag"
                    },
                    {
                        number: "EKL-WA10-B-A",
                        description: "Welch Allyn/ECG Leadwires, 10 lead, 0.9m",
                        brands: "Welch Allyn",
                        references: "401129",
                        package: "1 set/bag"
                    }
                ],
                },
                {
                title: "Patient Cables",
                brands: ["GE Healthcare", "Philips"],
                features: [
                    "Performance equivalent to OEMs",
                    "Secured connected with EKG unit and Module adaptors",
                    "Cost Savings up to 50%",
                    "CE certificate and FDA 510(K) clearance",
                    "12 months warranty",
                ],
                imageUrls: [
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Orantech-Patient-Cables-GE.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Orantech-Patient-Cables-GE1.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Orantech-Patient-Cables-GE2.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Orantech-Patient-Cables-GE3.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Orantech-Patient-Cables.jpg"
                ],
                downloadUrls: [
                    {
                        url: "https://orantech.com/wp-content/uploads/2021/03/User_Manual_EKG_Patient_Cables.pdf",
                        message: "EKG Patient Cables User Manual"
                    }
                ],
                popularProducts: [
                    {
                        number: "EKT-GE-AM4",
                        description: "Data Interface Cable, for AM4 module, 4.6m",
                        brands: "GE Healthcare",
                        references: "700044-202",
                        package: "1 pc/bag"
                    },
                    {
                        number: "EKT-GE-CAM14",
                        description: "Data Interface Cable, for CAM14 module, 4.6m",
                        brands: "GE Healthcare",
                        references: "2016560-003",
                        package: "1 pc/bag"
                    },
                    {
                        number: "EKT-GE-MAX1",
                        description: "Data Interface Cable, for MAX1 module, 4.6m",
                        brands: "GE Healthcare",
                        references: "700044-201",
                        package: "1 pc/bag"
                    },
                    {
                        number: "EKT-GE-MAXP",
                        description: "Data Interface Cable, for MAXP module, 4.6m",
                        brands: "GE Healthcare",
                        references: "700044-204",
                        package: "1 pc/bag"
                    }
                ],
                },
                {
                title: "EKG Accessories",
                brands: ["All brands"],
                features: [
                    "Limb Clamps and Chest Suctions for Adult and Pediatric",
                    "Selection of ECG adapters for specific electrode ends",
                    "Electrodes options: 4mm Banana, 3mm Needle, GE universal, Snap and Grabber",
                    "AHA/IEC code available",
                    "6 months warranty"
                ],
                imageUrls: [
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Accessories-EKA_BA_10.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Accessories-EKA_BG_10.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Accessories-EKA_BS_10.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Accessories-EKA_BW_10.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Accessories-EKA_NA_10.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Accessories-EKA_NW_10.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Orantech-adaptors-to-pinch.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Orantech-adaptors.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Orantech-chest-suctions-adult-and-pediatric.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Orantech-chest-suctions.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Orantech-GE-universal-adaptors-to-banana-end.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/EKG-Orantech-Limb-clamps.jpg"
                ],
                }
            ]
        })
        
    }
</script>

<style lang="scss" scoped>
.link-img {
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
}
</style>