<template lang="pug">
#spo2
    b-container.content-wrap.light-font.pt-2
        .entity-head
            b-row.entity-head-content.mb-4
                b-col(cols="12") 
                    h1.mt-blue.light-font.mb-2 Orthopedics
                b-col(cols="12" md="4")
                    b-img.link-img.w-100(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orth-Tourniquet-Accessories.jpg" alt="")
                b-col.mt-2(cols="12" md="8")
                    p Orantech started to expand the accessories category of Orthopedics equipment. Tourniquet system accessories are the first to be introduced and have been provided to our customers. Quality is the priority, which is the policy and also the mission at Orantech while we always keep the competitive prices than the other suppliers and OEM's.
        .item-wrap(v-for="(sp, i) in orthopedicsData" :key="i")
            AboutProductItem(
            :title="sp.title"
            :dateInfo="sp.dateInfo"
            :brands="sp.brands"
            :models="sp.models"
            :features="sp.features"
            :imageUrls="sp.imageUrls"
            :popularProducts="sp.popularProducts"
            :downloadUrls="sp.downloadUrls")
    MissionPromiseStatement
    Footer
</template>

<script>
  import AboutProductItem from '@/components/AboutProductItem.vue'
  import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
  import Footer from '@/components/Footer.vue';
  export default {
        components: {
            AboutProductItem,
            MissionPromiseStatement,
            Footer
        },
        data: () => ({
            orthopedicsData: [
                {
                    title: "Tourniquet Accessories",
                    brands: ["Zimmer Biomet", ],
                    features: [
                        "Engineered to functionally equivalent to OEMs",
                        "Color Coded Hoses",
                        "Universal Hose Adaptors",
                        "Reusable Cuffs",
                        "Disposable Cuffs",
                        "Cuff Sleeves"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orth-Tourniquet-Accessories.jpg"
                    ],
                },
            ]
        })
        
    }
</script>

<style lang="scss" scoped>
.link-img {
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
}
</style>