<template lang="pug">
#spo2
    b-container.content-wrap.light-font.pt-2
        .entity-head
            b-row.entity-head-content.mb-4
                b-col(cols="12") 
                    h1.mt-blue.light-font.mb-2 Batteries
                b-col(cols="12" md="4")
                    b-img.link-img.w-100(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Batteries-1.jpg" alt="")
                //- b-col.mt-2(cols="12" md="8")
                    //- p Orantech temperature products cover both long term monitoring as well as quick measurement for patients from neonate to adult. Our compatible products are ideal for after-sales replacements for the following primary brands: Atom, Drager/Siemens, GE, Mindray, Philips, Welch Allyn, YSI and more.
        .item-wrap(v-for="(sp, i) in tempData" :key="i")
            AboutProductItem(
            :title="sp.title"
            :dateInfo="sp.dateInfo"
            :brands="sp.brands"
            :models="sp.models"
            :features="sp.features"
            :imageUrls="sp.imageUrls"
            :popularProducts="sp.popularProducts"
            :downloadUrls="sp.downloadUrls")
    MissionPromiseStatement
    Footer
</template>

<script>
  import AboutProductItem from '@/components/AboutProductItem.vue'
  import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
  import Footer from '@/components/Footer.vue';
  export default {
        components: {
            AboutProductItem,
            MissionPromiseStatement,
            Footer
        },
        data: () => ({
            tempData: []
        })
        
    }
</script>

<style lang="scss" scoped>
.link-img {
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
}
</style>